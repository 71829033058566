import {Button, Form, Input, Select} from 'antd';
import {ArrowRightOutlined} from '@ant-design/icons';
import './style.scss';
import {useEffect, useState} from 'react';
import {listLocations} from '../../../api/smaService';

function ManagerForm({itemData, action, setDrawerOpen}) {
    const [locationsOptions, setLocationsOptions] = useState([]);

    useEffect(() => {
        const fetchLocations = async () => {
            const result = await listLocations();
            if (result.success) {
                setLocationsOptions(result.data.map(location => ({
                    label: location.name,
                    value: location.id
                })));
            }
        };
        fetchLocations();
    }, []);

    const onFinish = (values) => {
        const updatedValues = {
            ...values,
            location_ids: values.locations,
            id: itemData?.id
        };
        action(updatedValues);
        setDrawerOpen(false);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="NewManagers">
            <div className="new-managers-form">
                <Form
                    layout="vertical"
                    name="new-managers-form"
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    style={{maxWidth: 600}}
                    initialValues={{
                        name: itemData?.name || '',
                        email: itemData?.email || '',
                        locations: itemData?.locations || [],
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Manager name"
                        name="name"
                        rules={[{required: true, message: 'Please input the manager name!'}]}
                    >
                        <Input placeholder="Insert the name"/>
                    </Form.Item>
                    <Form.Item
                        label="Locations"
                        name="locations"
                        rules={[{required: true, message: 'Please select the locations!'}]}
                    >
                        <Select
                            mode="multiple"
                            options={locationsOptions}
                            placeholder="Select locations"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{required: true, message: 'Please input the manager email!'}]}
                    >
                        <Input placeholder="Insert the email"/>
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{required: !itemData, message: 'Please input the password!'}]}
                    >
                        <Input.Password placeholder="Insert the password"/>
                    </Form.Item>
                    <Form.Item
                        label="Confirm Password"
                        name="confirm_password"
                        dependencies={['password']}
                        rules={[
                            {required: !itemData, message: 'Please confirm the password!'},
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="Confirm the password"/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {itemData ? 'Update Manager' : 'Create Manager'}
                            <ArrowRightOutlined/>
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default ManagerForm;