import { VictoryArea, VictoryGroup, VictoryScatter } from 'victory';
import { formatCurrency } from 'utils/formatters';
import './style.scss';

function ProfitChart({ data, rangeType }) {
  // Obter apenas valores válidos de y
  const validYValues = data.map(d => d.y).filter(y => !isNaN(y) && y !== undefined);

  // Se não houver valores válidos, definir um domínio padrão
  const minY = validYValues.length > 0 ? Math.min(...validYValues) : 0;
  const maxY = validYValues.length > 0 ? Math.max(...validYValues) : 0;

  // Adicionar padding de 10% para evitar pontos nas bordas
  const paddingY = (maxY - minY) * 0.1;
  const domain = {
    x: [1, data.length], // Ajusta para o número de dias
    y: [minY - paddingY, maxY + paddingY]
  };

  return (
    <div className="ProfitChart">
      <div className="title">Profit per {rangeType}</div>
      <VictoryGroup
        data={data}
        domain={domain}
        height={265}
        padding={{
          left: 50, // Adicionar espaço para não cortar labels
          right: 50, // Adicionar espaço para não cortar labels
          bottom: 20, // Espaço para labels em baixo
          top: 65
        }}
        style={{
          parent: {
            background: "#F0F5FB",
            borderRadius: 16,
            overflow: "visible" // Garante que elementos não sejam cortados
          },
        }}>
        <VictoryScatter
          style={{
            data: {
              fill: ({ datum }) => datum.x === data.length ? "#9747FF" : "#E9E7FB", // Destaque para o último ponto
              stroke: "#9747FF",
              strokeWidth: 1
            }
          }}
        />
        <VictoryArea
          interpolation={"natural"}
          style={{
            data: {
              fill: "#9747FF",
              fillOpacity: 0.2,
              stroke: "#9747FF",
              strokeWidth: 1,
            },
            labels: {
              fontSize: 15,
              fill: "#9747FF",
              padding: 15
            }
          }}
          labels={({ datum }) => formatCurrency(datum.y)}
        />
      </VictoryGroup>
    </div>
  );
}

export default ProfitChart;
