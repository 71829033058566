import { Link } from "react-router-dom";
import { Dropdown, Button } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import './style.scss';

export const LinkOrEdit = ({children, link, action, className}) => {
  return link ?
    <Link to={link} className={className}>{children}</Link>
    :
    <div onClick={action} className={className}>{children}</div>
}
    
const CardListItem = ({data, className = ''}) => {
  const { link, title, list, extraData, icon, actions } = data;

  const getMenuItems = (items) => {
    if (!items?.length) return [];
    return items.map((menuItem) => ({
      key: Math.random(),
      label: <span onClick={() => menuItem.action(data)}>{ menuItem.name }</span>
    }))
  };

  const menuItems = getMenuItems(actions);

  return (
    <div className={`cardList-item ${className}`}>
      {menuItems?.length && 
        <Dropdown
          menu={{items: menuItems}}
          placement="bottomRight"
          trigger={['click']}
        >
          <Button className="cardList-item-actions-menu"><EllipsisOutlined /></Button>
        </Dropdown>
      }
      <LinkOrEdit link={link} action={() => actions[0]?.action(data)} className="cardList-item-content">
        <div className="cardList-item-title-box">
          {icon && <div className="cardList-item-icon">{icon}</div>}
          {title && <div className="cardList-item-title">{title}</div>}
        </div>
        <div className="cardList-item-data-box">
          {extraData && <div className="cardList-item-extraData">{extraData}</div>}
          {list?.length && 
            <div className="cardList-item-list">
              {list.map((listItem, key) =>
                <div className="cardList-item-list-item" key={key}>
                  {listItem.icon && <span className="cardList-item-list-item-icon">{listItem.icon}</span>}
                  <span className="cardList-item-list-item-text">{listItem.text}</span>
                </div>
              )}
            </div>
          }
        </div>
      </LinkOrEdit>
    </div>
  );
};

export default CardListItem;