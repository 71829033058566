import {useNavigate} from "react-router-dom";
import {Button} from 'antd';
import './style.scss';
import {handleLogout} from "../../../api/authService";
import {useStoreInContext} from "../../../store/main";


function Logout({onClose}) {
    const navigate = useNavigate();
    const {setLoggedUser} = useStoreInContext(state => state);

    const onConfirmLogout = () => {
        handleLogout(navigate, setLoggedUser);
    };

    return (
        <div className="Logout">
            <div className="logout-buttons">
                <Button type="default" onClick={onClose}>
                    Back
                </Button>
                <Button type="primary" onClick={onConfirmLogout}>
                    Confirm Logout
                </Button>
            </div>
        </div>
    );
}

export default Logout;