import './style.scss';

function Error() {
  return (
    <div className="Error">
      Error
    </div>
  );
}

export default Error;
