import { AppstoreOutlined, EnvironmentOutlined, NotificationOutlined, TeamOutlined } from '@ant-design/icons';
import ManageMenuItem from './ManageMenuItem';
import Header from "components/Header";
import { useOutletContext, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { message } from 'antd';
import Logout from "./Logout";
import AccountForm from './AccountForm';
import CompanyForm from './CompanyForm';
import { useStoreInContext } from 'store/main';
import './style.scss';

function Manage() {
  let { id } = useParams();
  const { setDrawerOpen, setDrawerProps } = useOutletContext();
  const { editLoggedUser, loggedUser } = useStoreInContext(state => state);
  const company = loggedUser.company;
  const userRole = loggedUser.role;  // Considerando que a role do usuário está em loggedUser.role

  useEffect(() => {
    if (id && id === 'account') {
      handleEditAccount(loggedUser);
    }
    if (id && id === 'company') {
      handleEditCompany(company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showComingSoonMessage = () => {
    message.info('Available soon.');
  };

  const handleEditAccount = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Your Account',
      height: 520,
      content: <AccountForm
          itemData={item}
          action={editLoggedUser}
          setDrawerOpen={setDrawerOpen} />
    })
    return setDrawerOpen(true);
  }

  const handleEditCompany = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Your Company',
      height: 640,
      content: <CompanyForm
          itemData={item}
          action={editLoggedUser}
          setDrawerOpen={setDrawerOpen} />
    })
    return setDrawerOpen(true);
  }

  const handleLogout = () => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Logout',
      content: <Logout onClose={() => setDrawerOpen(false)} />
    });
    setDrawerOpen(true);
  };

  return (
      <div className="Manage">
        <Header title="Manage">
          <div className="featured-menu-items">
            {(userRole === 'ceo') && (
                <>
                  <ManageMenuItem
                      link="/manage/technicians"
                      title="Technicians"
                      subtitle="List, Edit and create new technical users"
                      icon={<TeamOutlined />}
                      type="boxed"
                  />
                  <ManageMenuItem
                      link="/manage/managers"
                      title="Managers"
                      subtitle="List, Edit and create new manager users"
                      icon={<NotificationOutlined />}
                      type="boxed"
                  />
                </>
            )}
            {(userRole === 'ceo' || userRole === 'manager') && (
                <>
                  <ManageMenuItem
                      link="/manage/locations"
                      title="Locations"
                      subtitle="List, Edit and create new locations"
                      icon={<EnvironmentOutlined />}
                      type="boxed"
                  />
                  <ManageMenuItem
                      link="/machines"
                      title="Machines"
                      subtitle="List, Edit and create new machines"
                      icon={<AppstoreOutlined />}
                      type="boxed"
                  />
                </>
            )}
          </div>
          <div className="menu-list">
            <ManageMenuItem
                action={() => handleEditAccount(loggedUser)}
                title="Your account"
                subtitle="View and edit your data, and password."
            />
            {(userRole === 'ceo') && (
                <ManageMenuItem
                    action={() => handleEditCompany(loggedUser)}
                    title="Your company"
                    subtitle="View and edit your company information."
                />
            )}
          </div>
        </Header>
        <div className="bottom-menu">
          <ManageMenuItem
              action={handleLogout}
              title="Logout"
              subtitle="Logout of your account."
          />
        </div>
      </div>
  );
}

export default Manage;