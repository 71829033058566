import {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import {Button, message} from 'antd';
import Header from "components/Header";
import FloatButton from "components/FloatButton";
import CardList from "components/CardList";
import TechnicianForm from './TechnicianForm';
import './style.scss';
import {MailOutlined} from "@ant-design/icons";
import {createTechnician, listTechnicians, updateTechnician, deleteTechnician} from '../../api/smaService';

function Technicians() {
    const {setDrawerOpen, setDrawerProps} = useOutletContext();
    const [technicians, setTechnicians] = useState(null);

    // Fetch technicians on component load
    useEffect(() => {
        const fetchTechnicians = async () => {
            const result = await listTechnicians();
            if (result.success) {
                setTechnicians(result.data);
            } else {
                message.error(result.message);
            }
        };
        fetchTechnicians();
    }, []);

    const refreshTechnicians = async () => {
        const result = await listTechnicians();
        if (result.success) {
            setTechnicians(result.data);
        } else {
            message.error(result.message);
        }
    };

    const formattedCardListData = (cardData, actions) => {
        if (!cardData) return [];

        return cardData.map((item) => ({
            title: item.name,
            list: [
                {
                    icon: <MailOutlined/>,
                    text: item.email
                }
            ],
            actions: [
                {
                    action: () => actions.handleEditItem(item),
                    name: 'Edit Technician'
                },
                {
                    action: () => actions.handleDeleteItem(item),
                    name: 'Delete Technician'
                },
            ]
        }));
    };

    const deleteTechnicianOnDatabase = async (technician) => {
        try {
            let result = await deleteTechnician(technician.id);
            if (result.success) {
                message.success(result.message);
                await refreshTechnicians(); // Refresh the list after deletion
                setDrawerOpen(false);
            } else {
                message.error(result.message);
            }
        } catch (error) {
            console.error('Error:', error.message);
            message.error(`Failed to delete technician: ${error.message}`);
        }
    };

    const updateTechnicianOnDatabase = async (technician) => {
        try {
            let result = await updateTechnician(technician.id, technician);
            if (result.success) {
                message.success(result.message);
                await refreshTechnicians(); // Refresh the list after update
                setDrawerOpen(false);
            } else {
                message.error(result.message);
            }
        } catch (error) {
            console.error('Error:', error.message);
            message.error(`Failed to update technician: ${error.message}`);
        }
    };

    const createTechnicianOnDatabase = async (newTechnician) => {
        try {
            let result = await createTechnician(newTechnician);
            if (result.success) {
                message.success(result.message);
                await refreshTechnicians(); // Refresh the list after creation
                setDrawerOpen(false);
            } else {
                message.error(result.message);
            }
        } catch (error) {
            console.error('Error:', error.message);
            message.error(`Failed to create technician: ${error.message}`);
        }
    };

    const handleNewItem = () => {
        setDrawerProps({
            closable: false,
            push: true,
            title: 'New Technician',
            height: 640,
            content: <TechnicianForm
                action={createTechnicianOnDatabase}
                setDrawerOpen={setDrawerOpen}/>
        });
        return setDrawerOpen(true);
    };

    const handleEditItem = (item) => {
        setDrawerProps({
            closable: false,
            push: true,
            title: 'Edit Technician',
            height: 640,
            content: <TechnicianForm
                itemData={item}
                action={updateTechnicianOnDatabase}
                setDrawerOpen={setDrawerOpen}/>
        });
        return setDrawerOpen(true);
    };

    const handleDeleteItem = (item) => {
        setDrawerProps({
            closable: false,
            push: true,
            title: 'Delete Technician',
            height: 320,
            content: <Button
                onClick={() => {
                    deleteTechnicianOnDatabase(item);
                    return setDrawerOpen(false);
                }}
                danger>
                Confirm deletion
            </Button>
        });
        return setDrawerOpen(true);
    };

    const dataList = formattedCardListData(technicians, {handleEditItem, handleDeleteItem});

    return (
        <div className="Technicians">
            <Header
                title={'Technicians'}
                refreshAction={refreshTechnicians}
            />
            {technicians ?
                <CardList
                    data={dataList}
                    createAction={handleNewItem}
                />
                :
                <>LOADING...</>
            }
            <FloatButton action={handleNewItem}/>
        </div>
    );
}

export default Technicians;