import moment from "moment";
import 'moment-timezone';
import { Button } from "antd";
import './style.scss';
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

const DateRangeNav = ({
  rangeType,
  selectedDate,
  setSelectedDate,
  allowFutureDates = false,
}) => {
  const formatDate = {
    day: {
      format: 'MM/DD/YY',
      id: 'days'
    },
    week: {
      format: 'MMM/DD',
      id: 'weeks'
    },
    month: {
      format: 'MMM/YY',
      id: 'months'
    },
    year: {
      format: 'YYYY',
      id: 'years'
    },
  }

  const disableNextButton =
    !allowFutureDates &&
    moment(selectedDate).isSame(moment(), "day");

  const handleDateNavigation = (number) => {
    return setSelectedDate(moment(selectedDate).add(number, formatDate[rangeType]?.id))
  }

  return (
    <div className="dateRangeNav">
      <Button className="dateRangeNav-button" onClick={() => handleDateNavigation(-1)}>
        <ArrowLeftOutlined /> Previous
      </Button>
      <div className="dateRangeNav-current previous">
        {rangeType !== 'week' ?
          moment(selectedDate).format(formatDate[rangeType]?.format)
          :
          (
            `${moment(selectedDate).clone().weekday(1).format(formatDate[rangeType]?.format)} - ${moment(selectedDate).clone().weekday(7).format(formatDate[rangeType]?.format)}`
          )
        }
      </div>
      <Button className="dateRangeNav-button next" onClick={() => handleDateNavigation(1)} disabled={disableNextButton}>
        Next <ArrowRightOutlined />
      </Button>
    </div>
  )
};

export default DateRangeNav;
