import { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Drawer } from 'antd';
import './style.scss';
    
const AuthLayout = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerProps, setDrawerProps] = useState({});
  return (
    <div className="auth-page">
      <Link to={'/'} className="auth-logo">
        <img src="/logo.svg" alt="SMA" />
      </Link>
      <Outlet context={{
        setDrawerOpen,
        setDrawerProps
      }} />
      <Drawer
        title="Basic Drawer"
        placement="bottom"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        maskClosable={true}
        destroyOnClose={true}
        {...drawerProps}
      >{drawerProps?.content}</Drawer>
    </div>
  );
};

export default AuthLayout;