import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Button, Drawer, message } from 'antd';
import { useStoreInContext } from 'store/main';
import Header from "components/Header";
import FloatButton from "components/FloatButton";
import CardList from "components/CardList";
import TaskForm from './TaskForm';
import InteractionForm from './InteractionForm';
import { createTicket, updateTicket, deleteTicket, listInteractions, listTickets } from 'api/smaService';
import { AppstoreOutlined, EnvironmentOutlined, UserOutlined } from "@ant-design/icons";
import './style.scss';

const statusList = {
  OPEN: { label: 'Open', color: 'red' },
  DOING: { label: 'Doing', color: 'yellow' },
  DONE: { label: 'Done', color: 'green' },
  CLOSED: { label: 'Closed', color: 'grey' },
};

const getClassByStatus = (status) => statusList[status]?.color || '';

function Tasks() {
  const { setDrawerOpen, setDrawerProps } = useOutletContext();
  const { loggedUser } = useStoreInContext(state => state);
  const [tasks, setTasks] = useState([]);
  const [interactions, setInteractions] = useState([]);
  const [interactionDrawerVisible, setInteractionDrawerVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const fetchTickets = async () => {
    const response = await listTickets();
    if (response.success) {
      setTasks(response.data);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const handleNewItem = () => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'New Task',
      height: 640,
      content: <TaskForm action={handleCreateTicket} setDrawerOpen={setDrawerOpen} />
    });
    return setDrawerOpen(true);
  };

  const handleEditItem = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Edit Task',
      height: 640,
      content: <TaskForm itemData={item} action={handleUpdateTicket} setDrawerOpen={setDrawerOpen} />
    });
    return setDrawerOpen(true);
  };

  const handleDeleteItem = async (item) => {
    await deleteTicket(item.id);
    message.success('Ticket deleted successfully');
    setDrawerOpen(false);
    fetchTickets(); // Recarregando a lista de tarefas após deletar
  };

  const handleViewInteractions = async (item) => {
    setSelectedTask(item);
    const response = await listInteractions(item.id);
    if (response.success) {
      setInteractions(response.data);
    }
    setInteractionDrawerVisible(true);
  };

  const handleCreateTicket = async (values) => {
    await createTicket(values);
    message.success('Ticket created successfully');
    setDrawerOpen(false);
    fetchTickets(); // Recarregando a lista de tarefas após criar
  };

  const handleUpdateTicket = async (id, values) => {
    await updateTicket(id, values);
    message.success('Ticket updated successfully');
    setDrawerOpen(false);
    fetchTickets(); // Recarregando a lista de tarefas após atualizar
  };

  const dataList = tasks.map(task => ({
    icon: <div className={`task-status-dot ${getClassByStatus(task.status)}`}></div>,
    title: task.description,
    list: [
      { icon: <UserOutlined />, text: task.technician?.name || 'Unassigned' },
      { icon: <AppstoreOutlined />, text: task.machine?.name },
      { icon: <EnvironmentOutlined />, text: task.machine?.location.name },
      { icon: <div className={`anticon task-status-dot ${getClassByStatus(task.status)}`}></div>, text: statusList[task.status]?.label }
    ],
    actions: [
      { action: () => handleEditItem(task), name: 'Edit Task' },
      { action: () => handleDeleteItem(task), name: 'Delete Task' },
      { action: () => handleViewInteractions(task), name: 'View Interactions' },
    ]
  }));

  return (
      <div className="Tasks">
        <Header title={'Tasks'}>
          {/* Adicione filtros ou elementos adicionais aqui */}
        </Header>
        <CardList data={dataList} createAction={loggedUser.role !== 'technician' ? handleNewItem : null} />
        {loggedUser.role !== 'technician' && <FloatButton action={handleNewItem} />}

        <Drawer
            title="Interactions"
            placement="right"
            onClose={() => setInteractionDrawerVisible(false)}
            open={interactionDrawerVisible}
        >
          <InteractionForm task={selectedTask} interactions={interactions} />
        </Drawer>
      </div>
  )
}

export default Tasks;