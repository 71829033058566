import { LinkOrEdit } from "components/CardList/CardListItem";
import './style.scss';

function ManageMenuItem({type = 'list', title, subtitle, icon, link, action}) {
  return (  
    <LinkOrEdit link={link} action={action} className={`ManageMenuItem style-${type}`}>
      {icon && <div className="menu-icon">{icon}</div>}
      <b className="menu-title">{title}</b>
      {subtitle && <div className="menu-subtitle">{subtitle}</div>}
    </LinkOrEdit>
  );
}

export default ManageMenuItem;
