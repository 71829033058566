import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Select } from 'antd';
import {
  ArrowRightOutlined
} from '@ant-design/icons';
import './style.scss';
import { usaStates } from "utils/enums";

function LocationForm({ itemData, action, setDrawerOpen }) {
  const navigate = useNavigate();

  const onFinish = (values) => {
    // Adiciona o id ao objeto values antes de passar para a função de ação
    const updatedValues = { ...values, id: itemData?.id };
    action(updatedValues);
    setDrawerOpen(false);
    navigate("/manage/locations");
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="NewLocations">
      <div className="new-locations-form">
        <Form
          layout="vertical"
          name="new-locations-form"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{
            name: itemData?.name || undefined,
            location_code: itemData?.location_code || undefined,
            zipcode: itemData?.zipcode || undefined,
            address1: itemData?.address1 || undefined,
            address2: itemData?.address2 || undefined,
            city: itemData?.city || undefined,
            state: itemData?.state || undefined
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {itemData?.location_code && itemData?.id && (
            <Form.Item
              label="Location Code"
              name="location_code"
            >
              <Input disabled={true} placeholder="Location ID" />
            </Form.Item>
          )}
          <Form.Item
            label="Location name"
            name="name"
            rules={[{ required: true, message: 'Please input the location name!' }]}
          >
            <Input placeholder="Insert the name" />
          </Form.Item>
          <Form.Item
            label="Zipcode"
            name="zipcode"
            rules={[{ required: true, message: 'Please input the zipcode!' }]}
          >
            <Input placeholder="Insert the zipcode" />
          </Form.Item>
          <Form.Item
            label="Address line 1"
            name="address1"
            rules={[{ required: true, message: 'Please input the address first line!' }]}
          >
            <Input placeholder="Insert the adress first line" />
          </Form.Item>
          <Form.Item
            label="Address line 2"
            name="address2"
            rules={[{ required: false, message: 'Please input the address second line!' }]}
          >
            <Input placeholder="Insert the adress second line" />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: 'Please input the city!' }]}
          >
            <Input placeholder="Insert the city" />
          </Form.Item>
          <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: 'Please select the state!' }]}
          >
            <Select showSearch options={usaStates} placeholder="Select the state" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {itemData ? 'Update Location' : 'Create location'}
              <ArrowRightOutlined />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default LocationForm;
