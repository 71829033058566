// src/pages/CustomSearch.js

import React, { useEffect, useState } from 'react';
import { Select, DatePicker, TimePicker, Button, Form, Alert, Spin, Table, message, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useStoreInContext } from 'store/main';
import dayjs from 'dayjs';
import Header from 'components/Header';
import './styles.scss';
import { fetchDeviceDataAggregated } from '../../api/dataService';
import { listMachinesByLocation } from '../../api/smaService';
import { formatCurrency } from 'utils/formatters';

const { Option } = Select;

const CustomSearch = () => {
    const { locations } = useStoreInContext(state => state);
    const [form] = Form.useForm();
    const [machines, setMachines] = useState([]);
    const [loading, setLoading] = useState(false);
    const [aggregatedData, setAggregatedData] = useState([]);
    const [error, setError] = useState(null);
    const [hasSearched, setHasSearched] = useState(false);

    // Estados para os totais
    const [totalCashIn, setTotalCashIn] = useState(0);
    const [totalCashOut, setTotalCashOut] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);

    // Função para obter máquinas com base nas localizações selecionadas
    const fetchMachines = async (locationIds) => {
        try {
            if (locationIds.length === 0) {
                setMachines([]);
                return;
            }
            const response = await listMachinesByLocation(locationIds);
            setMachines(response.data);
        } catch (err) {
            console.error('Error fetching machines:', err);
            message.error('Error fetching machines.');
        }
    };

    // Handler quando as localizações são selecionadas
    const handleLocationChange = (locationIds) => {
        fetchMachines(locationIds);
        // Resetar os campos de máquina quando as localizações mudam
        form.setFieldsValue({ machines: [] });
    };

    // Handler para submissão do formulário
    const onFinish = async (values) => {
        const { initialDate, initialTime, finalDate, finalTime, locations, machines } = values;

        if (!initialDate || !finalDate) {
            setError('Please select both initial and final dates.');
            return;
        }

        // Extrair data e hora como strings
        const initialDateStr = initialDate.format('YYYY-MM-DD');
        const initialTimeStr = initialTime ? initialTime.format('HH:mm:ss') : '00:00:00';
        const finalDateStr = finalDate.format('YYYY-MM-DD');
        const finalTimeStr = finalTime ? finalTime.format('HH:mm:ss') : '23:59:59';

        // Combinar data e hora em strings completas
        const startDateTimeStr = `${initialDateStr}T${initialTimeStr}`;
        const endDateTimeStr = `${finalDateStr}T${finalTimeStr}`;

        // Opcionalmente, converter as strings em objetos dayjs sem fuso horário
        const startDateTime = dayjs(startDateTimeStr, 'YYYY-MM-DDTHH:mm:ss', true);
        const endDateTime = dayjs(endDateTimeStr, 'YYYY-MM-DDTHH:mm:ss', true);

        // Validar datas
        if (!startDateTime.isValid() || !endDateTime.isValid()) {
            setError('Invalid date or time format.');
            return;
        }

        if (startDateTime.isAfter(endDateTime)) {
            setError('Initial date and time must be before final date and time.');
            return;
        }

        setError(null);
        setLoading(true);
        setHasSearched(true);

        const filters = {
            start_date: startDateTimeStr, // Enviar as datas como strings sem fuso horário
            end_date: endDateTimeStr,
            location_ids: locations,
            nodes: machines || [],
        };

        console.log("Data inicial", startDateTimeStr);
        console.log("Data final", endDateTimeStr);

        try {
            const response = await fetchDeviceDataAggregated(filters);
            // Ordenar os dados por sending_node antes de definir o estado
            const sortedData = response.sort((a, b) => {
                if (a.sending_node < b.sending_node) return -1;
                if (a.sending_node > b.sending_node) return 1;
                return 0;
            });
            setAggregatedData(sortedData);

            // Calcular os totais acumulados
            const calculatedTotalCashIn = sortedData.reduce((sum, item) => sum + parseFloat(item.total_coin_in || 0), 0);
            const calculatedTotalCashOut = sortedData.reduce((sum, item) => sum + parseFloat(item.total_cashout || 0), 0);
            const calculatedTotalProfit = calculatedTotalCashIn - calculatedTotalCashOut;

            setTotalCashIn(calculatedTotalCashIn);
            setTotalCashOut(calculatedTotalCashOut);
            setTotalProfit(calculatedTotalProfit);

            if (sortedData.length === 0) {
                message.info('No data found for selected filters.');
            }
        } catch (err) {
            console.error('Error fetching aggregated data:', err);
            setError('Error fetching aggregated data.');
        } finally {
            setLoading(false);
        }
    };

    // Definir colunas para a tabela de resultados
    const columns = [
        {
            title: 'Machine',
            dataIndex: 'machine_name',
            key: 'machine_name',
            width: 110, // Aumentar a largura
            align: 'left', // Alinhar à esquerda
            ellipsis: true, // Adicionar elipses para textos longos
            render: (text, record) => {
                // Supondo que 'node' está presente em cada registro
                const node = record.sending_node;
                return `${node} - ${text}`;
            },
        },
        {
            title: 'In',
            dataIndex: 'total_coin_in',
            key: 'total_coin_in',
            width: 60, // Reduzir a largura
            align: 'center', // Manter centralizado
            render: (text) => formatCurrency(parseFloat(text)),
        },
        {
            title: 'Out',
            dataIndex: 'total_cashout',
            key: 'total_cashout',
            width: 60, // Reduzir a largura
            align: 'center', // Manter centralizado
            render: (text) => `-${formatCurrency(parseFloat(text))}`,
        },
        {
            title: 'Profit',
            dataIndex: 'profit',
            key: 'profit',
            width: 60, // Reduzir a largura
            align: 'center', // Manter centralizado
            render: (text) => {
                const profit = parseFloat(text);
                const profitClass = profit >= 0 ? 'profit-positive' : 'profit-negative';
                return <span className={profitClass}>{formatCurrency(profit)}</span>;
            },
        },
    ];

    // Definir colunas para a tabela de totais
    const totalColumns = [
        {
            title: 'Cash In',
            dataIndex: 'totalCashIn',
            key: 'totalCashIn',
            width: 200,
            align: 'left',
            render: (text) => <strong>{formatCurrency(text)}</strong>,
        },
        {
            title: 'Cash Out',
            dataIndex: 'totalCashOut',
            key: 'totalCashOut',
            width: 200,
            align: 'left',
            render: (text) => <strong>-{formatCurrency(text)}</strong>,
        },
        {
            title: 'Profit',
            dataIndex: 'totalProfit',
            key: 'totalProfit',
            width: 200,
            align: 'left',
            render: (text) => {
                const profit = parseFloat(text);
                const profitClass = profit >= 0 ? 'profit-positive' : 'profit-negative';
                return <strong className={profitClass}>{formatCurrency(profit)}</strong>;
            },
        },
    ];

    // Função para limitar a seleção de datas
    const disabledDate = (current) => {
        const startLimit = dayjs().subtract(2, 'years');
        const endLimit = dayjs().add(2, 'years');
        return current && (current < startLimit || current > endLimit);
    };

    return (
        <div className="CustomSearch">
            <Header
                title="Search"
                // Adicione uma ação de voltar se necessário
            />
            <div className="custom-search-container">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        initialDate: dayjs().subtract(7, 'days'),
                        initialTime: dayjs(),
                        finalDate: dayjs(),
                        finalTime: dayjs(),
                    }}
                >
                    {/* Seleção de Localizações */}
                    <Form.Item
                        label="Locations"
                        name="locations"
                        rules={[{ required: true, message: 'Select at least one location.' }]}
                    >
                        <Select
                            placeholder="Select location(s)"
                            onChange={handleLocationChange}
                            allowClear
                            showSearch={false} // Desabilitar a digitação, conforme solicitado anteriormente
                        >
                            {locations.map(location => (
                                <Option key={location.id} value={location.id}>
                                    {location.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/* Seleção de Máquinas (Opcional) */}
                    <Form.Item
                        label="Machines"
                        name="machines"
                        dependencies={['locations']}
                        rules={[
                            // Tornar a seleção de máquinas opcional
                            // Se desejar adicionar validações específicas, ajuste conforme necessário
                        ]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Select machines (optional)"
                            allowClear
                            disabled={machines.length === 0}
                            showSearch={false} // Opcional: Desabilitar a digitação
                        >
                            {machines.map(machine => (
                                <Option key={machine.id} value={machine.node}>
                                    {machine.name} (Node: {machine.node})
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/* Seleção de Datas e Horas */}
                    <Row gutter={16}>
                        {/* Initial Date */}
                        <Col xs={24} sm={6}>
                            <Form.Item
                                label="Initial Date"
                                name="initialDate"
                                rules={[{ required: true, message: 'Select initial date.' }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    disabledDate={disabledDate}
                                    placeholder="Select initial date"
                                    format="MM/DD/YYYY"
                                />
                            </Form.Item>
                        </Col>

                        {/* Initial Time */}
                        <Col xs={24} sm={6}>
                            <Form.Item
                                label="Initial Time"
                                name="initialTime"
                                rules={[{ required: true, message: 'Select initial time.' }]}
                            >
                                <TimePicker
                                    style={{ width: '100%' }}
                                    placeholder="Select initial time"
                                    format="HH:mm:ss"
                                    size="small" // Definir tamanho pequeno
                                    showNow={false} // Opcional: Remove o botão "Now"
                                />
                            </Form.Item>
                        </Col>

                        {/* Final Date */}
                        <Col xs={24} sm={6}>
                            <Form.Item
                                label="Final Date"
                                name="finalDate"
                                rules={[{ required: true, message: 'Select final date.' }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    disabledDate={disabledDate}
                                    placeholder="Select final date"
                                    format="MM/DD/YYYY"
                                />
                            </Form.Item>
                        </Col>

                        {/* Final Time */}
                        <Col xs={24} sm={6}>
                            <Form.Item
                                label="Final Time"
                                name="finalTime"
                                rules={[{ required: true, message: 'Select final time.' }]}
                            >
                                <TimePicker
                                    style={{ width: '100%' }}
                                    placeholder="Select final time"
                                    format="HH:mm:ss"
                                    size="small" // Definir tamanho pequeno
                                    showNow={false} // Opcional: Remove o botão "Now"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Exibição de Erro */}
                    {error && (
                        <Form.Item>
                            <Alert message={error} type="error" showIcon />
                        </Form.Item>
                    )}

                    {/* Botão de Submissão */}
                    <Form.Item className="form-submit-button">
                        <Button type="primary" htmlType="submit">
                            Search <SearchOutlined className="search-icon" />
                        </Button>
                    </Form.Item>
                </Form>

                {/* Indicador de Loading */}
                {loading && <Spin tip="Fetching data..." />}

                {/* Resultados da Busca */}
                {!loading && aggregatedData.length > 0 && (
                    <div className="results-section">
                        <h2>Results</h2>
                        <Table
                            className="custom-table" // Adicionar classe personalizada
                            dataSource={aggregatedData.map((item, index) => ({
                                key: index,
                                ...item,
                                profit: parseFloat(item.total_coin_in) - parseFloat(item.total_cashout),
                            }))}
                            columns={columns}
                            pagination={{
                                pageSize: 10,
                                position: ['bottomCenter'], // Centraliza a paginação
                            }}
                        />
                        {/* Nova Tabela de Totais */}
                        <div className="totals-section" style={{ marginTop: '20px' }}>
                            <h3>Totals</h3>
                            <Table
                                className="totals-table" // Classe personalizada para a tabela de totais
                                dataSource={[
                                    {
                                        key: 'totals',
                                        totalCashIn: totalCashIn,
                                        totalCashOut: totalCashOut,
                                        totalProfit: totalProfit,
                                    }
                                ]}
                                columns={totalColumns}
                                pagination={false}
                                bordered
                            />
                        </div>
                    </div>
                )}

                {/* Mensagem de Nenhum Resultado */}
                {!loading && hasSearched && aggregatedData.length === 0 && (
                    <div className="no-data">
                        <Alert message="No data found for selected filters." type="info" showIcon />
                    </div>
                )}
            </div>
        </div>
    );

}

export default CustomSearch;