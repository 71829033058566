import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Select } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import './style.scss';
import { formattedLocationOptions } from "utils/formatters";

function MachineForm({ itemData, action, setDrawerOpen, locations }) {
    const navigate = useNavigate();

    // Converte as locations em opções de seleção
    const locationsOptions = formattedLocationOptions(locations);

    const onFinish = (values) => {
        const updatedValues = { ...values, id: itemData?.id };
        action(updatedValues);
        setDrawerOpen(false);
        return navigate("/machines");
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    console.log("Machine edit: ", itemData);

    return (
        <div className="NewMachines">
            <div className="new-machines-form">
                <Form
                    layout="vertical"
                    name="new-machines-form"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{
                        name: itemData?.name || undefined,
                        id: itemData?.id || undefined,
                        node: itemData?.node !== undefined ? String(itemData?.node) : undefined, // Converte o valor para string
                        location: itemData?.location || undefined,
                        remember: true
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Machine name"
                        name="name"
                        rules={[{ required: true, message: 'Please input the machine name!' }]}
                    >
                        <Input placeholder="Insert the name" />
                    </Form.Item>
                    <Form.Item
                        label="Machine node"
                        name="node"
                        rules={[{ required: true, message: 'Please input the Machine node!' }]}
                    >
                        <Input placeholder="Insert the machine node" />
                    </Form.Item>
                    <Form.Item
                        label="Location"
                        name="location"
                        rules={[{ required: true, message: 'Please input the location!' }]}
                    >
                        <Select options={locationsOptions} placeholder="Insert the location" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {itemData ? 'Update Machine' : 'Create machine'}
                            <ArrowRightOutlined />
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default MachineForm;