import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Drawer } from 'antd';
import Menu from "../../Menu";
import './style.scss';
    
const Layout = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/';
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerProps, setDrawerProps] = useState({});

  return (
    <div className={`app-container${isLoginPage ? ' login-page' : ''}`}>
      <Outlet context={{
        setDrawerOpen,
        setDrawerProps
      }} />
      <Drawer
        title="Basic Drawer"
        placement="bottom"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        maskClosable={true}
        destroyOnClose={true}
        {...drawerProps}
      >{drawerProps?.content}</Drawer>
      {!isLoginPage && <Menu location={location}/>}
    </div>
  );
};

export default Layout;