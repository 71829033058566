import { useOutletContext } from "react-router-dom";
import { Button, Select, message } from 'antd';
import Header from "components/Header";
import FloatButton from "components/FloatButton";
import CardList from "components/CardList";
import { formattedLocationOptions } from "utils/formatters";
import MachineForm from './MachineForm';
import './style.scss';
import { createMachine, listMachines, updateMachine, deleteMachine, listLocations } from '../../api/smaService';
import { EnvironmentOutlined, TagOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from 'react';

function Machines() {
  const { setDrawerOpen, setDrawerProps } = useOutletContext();
  const [machines, setMachines] = useState(null);
  const [locations, setLocations] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  // Fetch machines and locations on component load
  useEffect(() => {
    const fetchData = async () => {
      try {
        const machinesResult = await listMachines();
        const locationsResult = await listLocations();

        if (machinesResult.success) {
          setMachines(machinesResult.data);
        } else {
          message.error(machinesResult.message);
        }

        if (locationsResult.success) {
          setLocations(locationsResult.data);
        } else {
          message.error(locationsResult.message);
        }
      } catch (error) {
        message.error("Failed to fetch data: " + error.message);
      }
    };

    fetchData();
  }, []);

  const refreshMachines = async () => {
    const result = await listMachines();
    if (result.success) {
      setMachines(result.data);
    } else {
      message.error(result.message);
    }
  };

  // Função para obter o nome da Location pelo ID
  const getLocationNameById = (locations, locationId) => {
    const location = locations.find(location => location.id === locationId);
    return location ? location.name : 'Unknown Location';
  };

  const formattedCardListData = (cardData, actions) => {
    if (!cardData) return [];

    // Ordenar cardData pelo campo node, convertendo-o para número
    const sortedCardData = cardData.sort((a, b) => parseInt(a.node) - parseInt(b.node));

    return sortedCardData.map((item) => ({
      link: `/machines/${item.id}`,
      title: item.name,
      list: [
        {
          icon: <EnvironmentOutlined />,
          text: getLocationNameById(locations, item.location)
        },
        {
          icon: <TagOutlined />,
          text: `Node: ${item.node}`
        },
      ],
      actions: [
        {
          action: () => actions.handleEditItem(item),
          name: 'Edit Machine'
        },
        {
          action: () => actions.handleDeleteItem(item),
          name: 'Delete Machine'
        },
      ]
    }));
  };

  const deleteMachineOnDatabase = async (machine) => {
    try {
      let result = await deleteMachine(machine.id);
      if (result.success) {
        message.success(result.message);
        await refreshMachines(); // Refresh the list after deletion
        setDrawerOpen(false);
      } else {
        message.error(result.message);
      }
    } catch (error) {
      console.error('Error:', error.message);
      message.error(`Failed to delete machine: ${error.message}`);
    }
  };

  const updateMachineOnDatabase = async (machine) => {
    try {
      let result = await updateMachine(machine.id, machine);
      if (result.success) {
        message.success(result.message);
        await refreshMachines(); // Refresh the list after update
        setDrawerOpen(false);
      } else {
        message.error(result.message);
      }
    } catch (error) {
      console.error('Error:', error.message);
      message.error(`Failed to update machine: ${error.message}`);
    }
  };

  const createMachineOnDatabase = async (newMachine) => {
    try {
      let result = await createMachine(newMachine);
      if (result.success) {
        message.success(result.message);
        await refreshMachines(); // Refresh the list after creation
        setDrawerOpen(false);
      } else {
        message.error(result.message);
      }
    } catch (error) {
      console.error('Error:', error.message);
      message.error(`Failed to create machine: ${error.message}`);
    }
  };

  const handleNewItem = () => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'New Machine',
      height: 640,
      content: <MachineForm
          locations={locations}
          action={createMachineOnDatabase}
          setDrawerOpen={setDrawerOpen}
      />
    });
    return setDrawerOpen(true);
  };

  const handleEditItem = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Edit Machine',
      height: 640,
      content: <MachineForm
          itemData={item}
          locations={locations}
          action={updateMachineOnDatabase}
          setDrawerOpen={setDrawerOpen} />
    });
    return setDrawerOpen(true);
  };

  const handleDeleteItem = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Delete Machine',
      height: 320,
      content: <Button
          onClick={() => {
            deleteMachineOnDatabase(item)
            return setDrawerOpen(false)
          }}
          danger>
        Confirm deletion
      </Button>
    });
    return setDrawerOpen(true);
  };

  const locationsOptions = formattedLocationOptions(locations);
  const filteredMachines = selectedLocationId
      ? machines.filter(machine => machine.location === selectedLocationId)
      : machines;

  const dataList = formattedCardListData(filteredMachines, { handleEditItem, handleDeleteItem });

  return (
      <div className="Machines">
        <Header
            title={'Machines'}
            refreshAction={refreshMachines}
        >
          <div className="form-item">
            <div className="form-item-label">Filter by location</div>
            <Select
                name="locationFilter"
                placeholder="Select the location"
                options={locationsOptions}
                onChange={value => setSelectedLocationId(value)} // Atualiza o estado quando a seleção muda
            />
          </div>
        </Header>
        {machines ?
            <CardList
                data={dataList}
                createAction={handleNewItem}
            />
            :
            <>LOADING...</>
        }
        <FloatButton action={handleNewItem} />
      </div>
  );
}

export default Machines;