import {useOutletContext} from "react-router-dom";
import {Button, message} from 'antd';
import Header from "components/Header";
import FloatButton from "components/FloatButton";
import CardList from "components/CardList";
import ManagerForm from './ManagerForm';
import './style.scss';
import {MailOutlined, UserOutlined} from "@ant-design/icons";
import {createManager, listManagers, updateManager, deleteManager} from '../../api/smaService';
import {useEffect, useState} from 'react';

function Managers() {
    const {setDrawerOpen, setDrawerProps} = useOutletContext();
    const [managers, setManagers] = useState(null);

    // Fetch managers on component load
    useEffect(() => {
        const fetchManagers = async () => {
            const result = await listManagers();
            if (result.success) {
                setManagers(result.data);
            } else {
                message.error(result.message);
            }
        };
        fetchManagers();
    }, []);

    const refreshManagers = async () => {
        const result = await listManagers();
        if (result.success) {
            setManagers(result.data);
        } else {
            message.error(result.message);
        }
    };

    const formattedCardListData = (cardData, actions) => {
        if (!cardData) return [];

        return cardData.map((item) => ({
            title: item.name,
            list: [
                {
                    icon: <MailOutlined/>,
                    text: item.email
                }
            ],
            actions: [
                {
                    action: () => actions.handleEditItem(item),
                    name: 'Edit Manager'
                },
                {
                    action: () => actions.handleDeleteItem(item),
                    name: 'Delete Manager'
                },
            ]
        }));
    };

    const deleteManagerOnDatabase = async (manager) => {
        try {
            let result = await deleteManager(manager.id);
            if (result.success) {
                message.success(result.message);
                await refreshManagers(); // Refresh the list after deletion
                setDrawerOpen(false);
            } else {
                message.error(result.message);
            }
        } catch (error) {
            console.error('Error:', error.message);
            message.error(`Failed to delete manager: ${error.message}`);
        }
    };

    const updateManagerOnDatabase = async (manager) => {
        try {
            let result = await updateManager(manager.id, manager);
            if (result.success) {
                message.success(result.message);
                await refreshManagers(); // Refresh the list after update
                setDrawerOpen(false);
            } else {
                message.error(result.message);
            }
        } catch (error) {
            console.error('Error:', error.message);
            message.error(`Failed to update manager: ${error.message}`);
        }
    };

    const createManagerOnDatabase = async (newManager) => {
        try {
            let result = await createManager(newManager);
            if (result.success) {
                message.success(result.message);
                await refreshManagers(); // Refresh the list after creation
                setDrawerOpen(false);
            } else {
                message.error(result.message);
            }
        } catch (error) {
            console.error('Error:', error.message);
            message.error(`Failed to create manager: ${error.message}`);
        }
    };

    const handleNewItem = () => {
        setDrawerProps({
            closable: false,
            push: true,
            title: 'New Manager',
            height: 640,
            content: <ManagerForm
                action={createManagerOnDatabase}
                setDrawerOpen={setDrawerOpen}/>
        });
        return setDrawerOpen(true);
    };

    const handleEditItem = (item) => {
        setDrawerProps({
            closable: false,
            push: true,
            title: 'Edit Manager',
            height: 640,
            content: <ManagerForm
                itemData={item}
                action={updateManagerOnDatabase}
                setDrawerOpen={setDrawerOpen}/>
        });
        return setDrawerOpen(true);
    };

    const handleDeleteItem = (item) => {
        setDrawerProps({
            closable: false,
            push: true,
            title: 'Delete Manager',
            height: 320,
            content: <Button
                onClick={() => {
                    deleteManagerOnDatabase(item);
                    return setDrawerOpen(false);
                }}
                danger>
                Confirm deletion
            </Button>
        });
        return setDrawerOpen(true);
    };

    console.log("Managers: ", managers);

    const dataList = formattedCardListData(managers, {handleEditItem, handleDeleteItem});

    return (
        <div className="Managers">
            <Header
                title={'Managers'}
                refreshAction={refreshManagers}
            />
            {managers ?
                <CardList
                    data={dataList}
                    createAction={handleNewItem}
                />
                :
                <>LOADING...</>
            }
            <FloatButton action={handleNewItem}/>
        </div>
    );
}

export default Managers;