import { Link } from "react-router-dom";
import {
  ReloadOutlined,
  ArrowLeftOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import './style.scss';

const Header = ({ children, title, subTitle, backLink, refreshAction, className }) => {

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className={`app-header ${className}`}>
      <div className="header-top">
        <div className="header-data">
          {backLink && (
            <Link to={backLink} className="back-btn">
              <ArrowLeftOutlined />
            </Link>
          )}
          {
            subTitle ? (
              <div className="title-with-subtitle">
                <div className="title">
                  {title}
                </div>
                <div className="subtitle">
                  {subTitle}
                </div>
                {refreshAction && <ReloadOutlined className="refresh-btn" onClick={refreshAction} />}
                <PrinterOutlined className="refresh-btn" onClick={handlePrint} />
              </div>
            ) : (
              <div className="title-without-subtitle">
                {title}
                {refreshAction && <ReloadOutlined className="refresh-btn" onClick={refreshAction} />}
                <PrinterOutlined className="refresh-btn" onClick={handlePrint} />
              </div>
            )
          }
        </div>
        <Link to={'/dashboard'} className="header-logo">
          <img src="/logo.svg" alt="SMA" />
        </Link>
      </div>
      {children &&
        <div className="header-components">
          {children}
        </div>
      }
    </div>
  );
};

export default Header;