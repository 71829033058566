export const formatCurrency = (number) => {
  // Determine if the number has decimals greater than zero
  const hasFractionalPart = number % 1 !== 0;

  // Create a formatter with appropriate fraction digit settings
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: hasFractionalPart ? 2 : 0,
    maximumFractionDigits: hasFractionalPart ? 2 : 0,
  });

  return formatter.format(number);
};

export const formattedLocationOptions = (locations) => {
  const allLocations = locations || [];

  return allLocations.map((location) => ({
    value: location.id,
    label: location.name,
  }))
}

export const formattedUserByRoleOptions = (users, roleId) => {
  const usersByRole = users?.filter(
    (user) => user.roleId === roleId) || [];

  return usersByRole.map((user) => ({
    value: user.id,
    label: user.name,
  }))
}

export const formattedMachinesByLocationOptions = (locationId, machines) => {
  const machinesByLocation = machines?.filter(
    (machine) => machine.locationId === locationId) || [];

  return machinesByLocation.map((location) => ({
    value: location.id,
    label: location.name,
  }))
}