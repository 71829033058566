import { Link } from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';
import './style.scss';
    
const FloatButton = ({icon = <PlusOutlined/>, action, className = ''}) => {
  return (
    <Link onClick={action} className={`float-button ${className}`}>
      {icon}
    </Link>
  );
};

export default FloatButton;