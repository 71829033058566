import axios from 'axios';

// Configurando a instância do Axios
const api = axios.create({
    baseURL: 'https://apiv2.servicemasterapp.com',
    //baseURL: 'http://localhost:8000',
    headers: {
        'Content-Type': 'application/json',
    },
});

// Função para obter o token de refresh
async function refreshToken() {
    try {
        const refresh_token = localStorage.getItem('refresh_token');
        const response = await axios.post('https://apiv2.servicemasterapp.com/user/token/refresh/', {
            refresh: refresh_token
        });

        const new_access_token = response.data.access;
        const new_refresh_token = response.data.refresh; // Novo refresh token

        // Atualizar o access_token e refresh_token no localStorage
        localStorage.setItem('access_token', new_access_token);
        localStorage.setItem('refresh_token', new_refresh_token); // Atualizando também o refresh_token

        // Retornar o novo token
        return new_access_token;
    } catch (error) {
        console.error('Erro ao tentar atualizar o token: ', error);
        throw error; // Rejeita a promessa em caso de erro
    }
}

// Interceptor para adicionar o token de autenticação a cada requisição
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('access_token'); // Supondo que o token é armazenado no localStorage
        console.log("Enviando token via header: ", token);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Interceptor de resposta para detectar quando o token expira
api.interceptors.response.use(
    (response) => {
        return response; // Se a resposta for bem-sucedida, simplesmente a retorna
    },
    async (error) => {
        const originalRequest = error.config;

        // Verifica se houve erro de autenticação e se o token já não foi renovado
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true; // Marca a requisição para evitar loop infinito

            try {
                const new_token = await refreshToken(); // Obtém novo token
                originalRequest.headers.Authorization = `Bearer ${new_token}`; // Atualiza o header com o novo token

                // Tenta refazer a requisição original com o novo token
                return api(originalRequest);
            } catch (refreshError) {
                console.error('Erro ao tentar atualizar o token de acesso: ', refreshError);
                return Promise.reject(refreshError); // Se a renovação falhar, rejeita a requisição
            }
        }

        return Promise.reject(error); // Para outros erros, rejeita a promessa
    }
);

// Exemplo de chamada para login
export const login = async (username, password) => {
    try {
        const response = await api.post('/user/token', { username, password });
        return {
            success: true,
            message: response.data.detail || 'Login successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            // O servidor respondeu com um status que não é 2xx
            console.error('Login error:', error.response.data);
            return {
                success: false,
                message: error.response.data.detail || 'Login failed',
                status: error.response.status,
            };
        } else if (error.request) {
            // A requisição foi feita, mas nenhuma resposta foi recebida
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            // Algo aconteceu ao configurar a requisição que acionou um erro
            console.error('Error setting up login request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

// Exemplo de chamada para criar um CEO
export const createCeo = async (ceoData) => {
    try {
        const response = await api.post('/register/ceo', ceoData);
        return response.data;
    } catch (error) {
        console.error('Error during CEO creation:', error);
        throw error;
    }
};

export const getUserInfo = async (token) => {
    try {
        const response = await api.get('/user/profile', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {
            success: true,
            message: 'Data loaded successfully',
            status: response.status,
            data: response.data.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error fetching user info:', error.response.data);
            return {
                success: false,
                message: error.response.data.detail || 'Failed to fetch user info',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const updateUserProfile = async (profileData) => {
    try {
        const response = await api.post('/user/profile', profileData);
        return {
            success: true,
            message: 'Profile updated successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error updating profile:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to update profile',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const requestPasswordReset = async (email) => {
    try {
        const response = await api.post('/user/password/reset', { email });
        console.log("Reset: ", response.data);
        return response.data;
    } catch (error) {
        if (error.response) {
            console.error('Error requesting password reset:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to request password reset',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const saveCompany = async (companyData) => {
    try {
        const response = await api.post('/api/v1/company', companyData);
        return {
            success: true,
            message: 'Company saved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error during company save:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to save company',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const getCompany = async () => {
    try {
        const response = await api.get('/api/v1/company');
        return {
            success: true,
            message: 'Company retrieved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error fetching company:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to fetch company',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const createLocation = async (locationData) => {
    try {
        const response = await api.post('/api/v1/locations/create', locationData);
        return {
            success: true,
            message: 'Location created successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error creating location:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to create location',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const listLocations = async () => {
    try {
        const response = await api.get('/api/v1/locations');
        return {
            success: true,
            message: 'Locations retrieved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error listing locations:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to list locations',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const getLocation = async (locationId) => {
    try {
        const response = await api.get(`/api/v1/locations/${locationId}`);
        return {
            success: true,
            message: 'Location retrieved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error retrieving location:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to retrieve location',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const updateLocation = async (locationId, locationData) => {
    try {
        const response = await api.put(`/api/v1/locations/${locationId}/update`, locationData);
        return {
            success: true,
            message: 'Location updated successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error updating location:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to update location',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const deleteLocation = async (locationId) => {
    try {
        const response = await api.delete(`/api/v1/locations/${locationId}/delete`);
        return {
            success: true,
            message: 'Location deleted successfully',
            status: response.status,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error deleting location:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to delete location',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const createMachine = async (machineData) => {
    try {
        const response = await api.post('/api/v1/machines/create', machineData);
        return {
            success: true,
            message: 'Machine created successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error creating machine:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to create machine',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const listMachines = async () => {
    try {
        const response = await api.get('/api/v1/machines');
        return {
            success: true,
            message: 'Machines retrieved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error listing machines:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to list machines',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const getMachine = async (machineId) => {
    try {
        const response = await api.get(`/api/v1/machines/${machineId}`);
        return {
            success: true,
            message: 'Machine retrieved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error retrieving machine:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to retrieve machine',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const updateMachine = async (machineId, machineData) => {
    try {
        const response = await api.put(`/api/v1/machines/${machineId}/update`, machineData);
        return {
            success: true,
            message: 'Machine updated successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error updating machine:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to update machine',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const deleteMachine = async (machineId) => {
    try {
        const response = await api.delete(`/api/v1/machines/${machineId}/delete`);
        return {
            success: true,
            message: 'Machine deleted successfully',
            status: response.status,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error deleting machine:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to delete machine',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const listMachinesByLocation = async (locationId) => {
    try {
        const response = await api.get(`/api/v1/machines/location/${locationId}`);
        return {
            success: true,
            message: 'Machines retrieved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error listing machines:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to list machines',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const createManager = async (managerData) => {
    try {
        const response = await api.post('/api/v1/managers/create', managerData);
        return {
            success: true,
            message: 'Manager created successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error creating manager:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to create manager',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const listManagers = async () => {
    try {
        const response = await api.get('/api/v1/managers');
        return {
            success: true,
            message: 'Managers retrieved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error listing managers:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to list managers',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const getManager = async (managerId) => {
    try {
        const response = await api.get(`/api/v1/managers/${managerId}`);
        return {
            success: true,
            message: 'Manager retrieved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error retrieving manager:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to retrieve manager',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const updateManager = async (managerId, managerData) => {
    try {
        const response = await api.put(`/api/v1/managers/${managerId}/update`, managerData);
        return {
            success: true,
            message: 'Manager updated successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error updating manager:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to update manager',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const deleteManager = async (managerId) => {
    try {
        const response = await api.delete(`/api/v1/managers/${managerId}/delete`);
        return {
            success: true,
            message: 'Manager deleted successfully',
            status: response.status,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error deleting manager:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to delete manager',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const createTechnician = async (technicianData) => {
    try {
        const response = await api.post('/api/v1/technicians/create', technicianData);
        return {
            success: true,
            message: 'Technician created successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error creating technician:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to create technician',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const listTechnicians = async () => {
    try {
        const response = await api.get('/api/v1/technicians');
        return {
            success: true,
            message: 'Technicians retrieved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error listing technicians:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to list technicians',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const getTechnician = async (technicianId) => {
    try {
        const response = await api.get(`/api/v1/technicians/${technicianId}`);
        return {
            success: true,
            message: 'Technician retrieved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error retrieving technician:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to retrieve technician',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const updateTechnician = async (technicianId, technicianData) => {
    try {
        const response = await api.put(`/api/v1/technicians/${technicianId}/update`, technicianData);
        return {
            success: true,
            message: 'Technician updated successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error updating technician:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to update technician',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const deleteTechnician = async (technicianId) => {
    try {
        const response = await api.delete(`/api/v1/technicians/${technicianId}/delete`);
        return {
            success: true,
            message: 'Technician deleted successfully',
            status: response.status,
        };
    } catch (error) {
        if (error.response) {
            console.error('Error deleting technician:', error.response.data);
            return {
                success: false,
                message: error.response.data.message || 'Failed to delete technician',
                status: error.response.status,
            };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return {
                success: false,
                message: 'No response from server',
            };
        } else {
            console.error('Error setting up request:', error.message);
            return {
                success: false,
                message: 'Unexpected error occurred',
            };
        }
    }
};

export const createTicket = async (ticketData) => {
    try {
        const response = await api.post('/api/v1/tickets/create', ticketData);
        return {
            success: true,
            message: 'Ticket created successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        handleErrorResponse(error, 'Failed to create ticket');
    }
};

export const listTickets = async () => {
    try {
        const response = await api.get('/api/v1/tickets');
        return {
            success: true,
            message: 'Tickets retrieved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        handleErrorResponse(error, 'Failed to list tickets');
    }
};

export const getTicket = async (ticketId) => {
    try {
        const response = await api.get(`/api/v1/tickets/${ticketId}`);
        return {
            success: true,
            message: 'Ticket retrieved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        handleErrorResponse(error, 'Failed to retrieve ticket');
    }
};

export const updateTicket = async (ticketId, ticketData) => {
    try {
        const response = await api.put(`/api/v1/tickets/${ticketId}/update`, ticketData);
        return {
            success: true,
            message: 'Ticket updated successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        handleErrorResponse(error, 'Failed to update ticket');
    }
};

export const deleteTicket = async (ticketId) => {
    try {
        const response = await api.delete(`/api/v1/tickets/${ticketId}/delete`);
        return {
            success: true,
            message: 'Ticket deleted successfully',
            status: response.status,
        };
    } catch (error) {
        handleErrorResponse(error, 'Failed to delete ticket');
    }
};

export const listInteractions = async (ticketId) => {
    try {
        const response = await api.get(`/api/v1/tickets/${ticketId}/interactions`);
        return {
            success: true,
            message: 'Interactions retrieved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        handleErrorResponse(error, 'Failed to list interactions');
    }
};

export const createInteraction = async (values) => {
    try {
        const response = await api.post(`/api/v1/interactions/create`, values);
        return {
            success: true,
            message: 'Interaction created successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        handleErrorResponse(error, 'Failed to create interaction');
    }
};

const handleErrorResponse = (error, defaultMessage) => {
    if (error.response) {
        console.error(defaultMessage, error.response.data);
        return {
            success: false,
            message: error.response.data.message || defaultMessage,
            status: error.response.status,
        };
    } else if (error.request) {
        console.error('No response received:', error.request);
        return {
            success: false,
            message: 'No response from server',
        };
    } else {
        console.error('Error setting up request:', error.message);
        return {
            success: false,
            message: 'Unexpected error occurred',
        };
    }
};

export const listTicketStatus = async () => {
    try {
        const response = await api.get('/api/v1/ticket/status');
        return {
            success: true,
            message: 'Ticket statuses retrieved successfully',
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        console.error('Error fetching ticket statuses:', error);
        return {
            success: false,
            message: 'Failed to fetch ticket statuses',
        };
    }
};

export default api;