import { useEffect, useState } from 'react';
import { useStoreInContext } from 'store/main';
import moment from 'moment';
import 'moment-timezone';
import ProfitChart from 'components/ProfitChart';
import Header from 'components/Header';
import DateRangeRadio from 'components/DateRangeRadio';
import DateRangeNav from 'components/DateRangeNav';
import CashCards from 'components/CashCards';
import TopItemTable from 'components/TopItemTable';
import { formatCurrency } from 'utils/formatters';
import './style.scss';
import { fetchChartData, fetchIntervalDataByLocation } from '../../../api/dataService';
import { getLocationDataById } from "utils";
import { useParams } from "react-router-dom";

function LocationsItem() {
  const { locations } = useStoreInContext(state => state);
  const [rangeType, setRangeType] = useState('day');
  const [selectedDate, setSelectedDate] = useState(moment());
  const [chartData, setChartData] = useState([]);
  const [totalCashIn, setTotalCashIn] = useState(0);
  const [totalCashOut, setTotalCashOut] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [profitGrowth, setProfitGrowth] = useState(0);
  const [topMachines, setTopMachines] = useState([]);

  let { id } = useParams();
  id = parseInt(id, 10);

  const title = getLocationDataById(locations, id)?.name;
  let location = getLocationDataById(locations, id);
  // Função para carregar os dados do gráfico
  const fetchAndSetChartData = async () => {
    let startDate;
    let endDate = selectedDate.clone();
    let columnTotalIn = 'daily_total_coin_in_and_remote_difference';
    let columnTotalOut = 'daily_total_cashout_difference';
    let dateFieldByType = 'date';

    switch (rangeType) {
      case 'day':
        startDate = endDate.clone().subtract(6, 'days');
        break;
      case 'week':
        startDate = moment(selectedDate).subtract(8, 'weeks').startOf('week');
        endDate = moment(selectedDate).endOf('isoWeek');
        columnTotalIn = 'weekly_total_coin_in_and_remote_difference';
        columnTotalOut = 'weekly_total_cashout_difference';
        dateFieldByType = 'week_end';
        console.log("Data início: ", startDate.format('YYYY-MM-DD'));
        console.log("Data fim: ", endDate.format('YYYY-MM-DD'));
        break;
      case 'month':
        startDate = moment(selectedDate).subtract(6, 'months').startOf('month');
        endDate = endDate.clone().endOf('month');
        columnTotalIn = 'monthly_total_coin_in_and_remote_difference';
        columnTotalOut = 'monthly_total_cashout_difference';
        dateFieldByType = 'month_end';
        break;
      case 'year':
        startDate = moment(selectedDate).subtract(4, 'years').startOf('year');
        endDate = endDate.clone().endOf('year');
        columnTotalIn = 'yearly_total_coin_in_and_remote_difference';
        columnTotalOut = 'yearly_total_cashout_difference';
        dateFieldByType = 'year_end';
        break;
      default:
        startDate = endDate.clone().subtract(6, 'days');
    }

    const locationCodes = getLocationCodesFromLocations(locations);

    const weeklyData = await fetchChartData(rangeType, startDate, endDate, locationCodes);

    // Agregar dados semanais por dia
    const aggregatedData = weeklyData.reduce((acc, item) => {
      const date = moment(item[dateFieldByType]).format('YYYY-MM-DD');

      if (!acc[date]) {
        acc[date] = { total_coin_in: 0, total_cash_out: 0 };
      }

      acc[date].total_coin_in += parseFloat(item[columnTotalIn]);
      acc[date].total_cash_out += parseFloat(item[columnTotalOut]);

      return acc;
    }, {});

    let completeData = [];

    if (rangeType === 'day') {
      // Crie um intervalo de datas completo para o tipo 'day'
      const dateRange = [];
      let currentDate = startDate.clone();
      while (currentDate.isSameOrBefore(endDate)) {
        dateRange.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'days');
      }

      // Preencher dados ausentes com zeros
      completeData = dateRange.map(date => {
        if (!aggregatedData[date]) {
          return { date, total_coin_in: 0, total_cash_out: 0 };
        }
        return { date, ...aggregatedData[date] };
      });
    } else {
      // Usar os dados agregados diretamente para week, month e year
      completeData = Object.keys(aggregatedData).map(date => ({
        date,
        ...aggregatedData[date]
      }));
    }

    // Formatar dados para o gráfico
    const formattedChartData = completeData.map((data, index) => {
      const totalCoinIn = data.total_coin_in || 0;
      const totalCashOut = data.total_cash_out || 0;
      const profit = totalCoinIn - totalCashOut;

      // Verifique se o lucro é um número, caso contrário, defina como zero
      const profitSafe = isNaN(profit) ? 0 : profit;

      return {
        x: index + 1, // Índice numérico para o eixo X do gráfico
        y: profitSafe // Lucro diário agregado, agora com verificações
      };
    });

    if (formattedChartData.length > 1) {
      const lastProfit = formattedChartData[formattedChartData.length - 1].y;
      const previousProfit = formattedChartData[formattedChartData.length - 2].y;
      let growth = 0;

      if (previousProfit !== 0) {
        growth = ((lastProfit - previousProfit) / Math.abs(previousProfit)) * 100;
      }

      const growthRounded = isFinite(growth) ? Math.round(growth * 100) / 100 : 0;
      setProfitGrowth(growthRounded);
    } else {
      setProfitGrowth(0);
    }


    setChartData(formattedChartData);
  };

  const getLocationCodesFromLocations = (locations) => {
    return locations.map(location => location.location_code);
  };

  const fetchDataAndCalculateTotals = async (date, subtractPeriod, periodType, rangeType, locationId) => {
    let endDate = moment(date);
    let startDate = endDate.clone().subtract(subtractPeriod, periodType).startOf(periodType);

    let previousEndDate = startDate.clone().subtract(1, periodType).endOf(periodType);
    let previousStartDate = previousEndDate.clone().subtract(subtractPeriod, periodType).startOf(periodType);

    let columnTotalIn = 'daily_total_coin_in_and_remote_difference';
    let columnTotalOut = 'daily_total_cashout_difference';

    switch (rangeType) {
      case 'week':
        columnTotalIn = 'weekly_total_coin_in_and_remote_difference';
        columnTotalOut = 'weekly_total_cashout_difference';
        endDate = date.clone().endOf('isoWeek');
        startDate = date.clone().startOf('isoWeek');
        previousEndDate = moment(date).subtract(1, 'weeks').endOf('isoWeek');
        previousStartDate = moment(date).subtract(1, 'weeks').startOf('isoWeek');
        break;
      case 'month':
        columnTotalIn = 'monthly_total_coin_in_and_remote_difference';
        columnTotalOut = 'monthly_total_cashout_difference';
        endDate = date.clone().endOf('month');
        startDate = date.clone().startOf('month');
        previousEndDate = date.clone().subtract(1, 'months').endOf('month');
        previousStartDate = date.clone().subtract(1, 'months').startOf('month');
        break;
      case 'year':
        columnTotalIn = 'yearly_total_coin_in_and_remote_difference';
        columnTotalOut = 'yearly_total_cashout_difference';
        endDate = date.clone().endOf('year');
        startDate = date.clone().startOf('year');
        previousEndDate = date.clone().subtract(1, 'years').endOf('year');
        previousStartDate = date.clone().subtract(1, 'years').startOf('year');
        break;
      default:
        break;
    }

    const currentData = await fetchIntervalDataByLocation(rangeType, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), locationId);
    const totalOutCurrent = currentData.reduce((acc, curr) => acc + parseFloat(curr[columnTotalOut]), 0);
    const totalInCurrent = currentData.reduce((acc, curr) => acc + parseFloat(curr[columnTotalIn]), 0);
    const totalProfitCurrent = totalInCurrent - totalOutCurrent;

    return {
      totalIn: totalInCurrent,
      totalOut: totalOutCurrent,
      totalProfit: totalProfitCurrent,
      currentData
    };
  };

  // Atualização da função fetchAndSetTotals para lidar com diferentes tipos de intervalo
  const fetchAndSetTotals = async () => {
    let subtractPeriod = 1;
    let periodType = 'days';
    let columnTotalIn = 'daily_total_coin_in_and_remote_difference';
    let columnTotalOut = 'daily_total_cashout_difference';

    switch (rangeType) {
      case 'week':
        subtractPeriod = 1;
        periodType = 'weeks';
        columnTotalIn = 'weekly_total_coin_in_and_remote_difference';
        columnTotalOut = 'weekly_total_cashout_difference';
        break;
      case 'month':
        subtractPeriod = 1;
        periodType = 'months';
        columnTotalIn = 'monthly_total_coin_in_and_remote_difference';
        columnTotalOut = 'monthly_total_cashout_difference';
        break;
      case 'year':
        subtractPeriod = 1;
        periodType = 'years';
        columnTotalIn = 'yearly_total_coin_in_and_remote_difference';
        columnTotalOut = 'yearly_total_cashout_difference';
        break;
      default:
        break;
    }

    const { totalIn, totalOut, totalProfit, growth, currentData } = await fetchDataAndCalculateTotals(selectedDate, subtractPeriod, periodType, rangeType, location.location_code);

    // Processar os dados de 'currentData' para calcular os lucros por máquina
    const machineProfits = currentData.reduce((acc, curr) => {
      const machineName = curr.machine_name;
      const sendingNode = curr.sending_node;
      const cashIn = parseFloat(curr[columnTotalIn]);
      const cashOut = parseFloat(curr[columnTotalOut]);
      const profit = cashIn - cashOut;

      if (!acc[machineName]) {
        acc[machineName] = {
          totalProfit: 0,
          device_code: curr.device_code,
          sending_node: sendingNode,
          cashIn: 0,
          cashOut: 0
        };
      }

      acc[machineName].totalProfit += profit;
      acc[machineName].cashIn += cashIn;
      acc[machineName].cashOut += cashOut;
      return acc;
    }, {});

    const sortedMachines = Object.keys(machineProfits)
      .map(machine => ({
        id: machine,
        device_code: machineProfits[machine].device_code,
        profit: machineProfits[machine].totalProfit,
        sending_node: machineProfits[machine].sending_node,
        cashIn: machineProfits[machine].cashIn,
        cashOut: machineProfits[machine].cashOut
      }))
      .sort((a, b) => a.sending_node - b.sending_node); // Ordenar pelo campo sending_node

    setTopMachines(sortedMachines);
    setTotalCashIn(totalIn);
    setTotalCashOut(totalOut);
    setTotalProfit(parseFloat(totalProfit.toFixed(2)));
    setProfitGrowth(growth);
  };

  useEffect(() => {
    fetchAndSetChartData();
    fetchAndSetTotals();
  }, [selectedDate, rangeType]);

  return (
    <div className="Dashboard">
      <Header
        title={title}
        backLink={'/manage/locations'}
        refreshAction={() => {
          fetchAndSetChartData();
          fetchAndSetTotals();
        }}
      >
        <DateRangeRadio
          rangeType={rangeType}
          setRangeType={setRangeType}
        />
        <DateRangeNav
          rangeType={rangeType}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </Header>
      <ProfitChart
        data={chartData}
        rangeType={rangeType}
        domain={{
          x: [1, 7],
          y: [Math.min(...chartData.map(d => d.y)), Math.max(...chartData.map(d => d.y))]
        }}
      />
      <CashCards
        rangeType={rangeType}
        growth={profitGrowth}
        profit={formatCurrency(totalProfit)}
        cashIn={formatCurrency(totalCashIn)}
        cashOut={formatCurrency(totalCashOut)}
      />
      <TopItemTable
        title="Machines - Cashin/Cashout"
        data={topMachines.map((machine, index) => ({
          key: index,
          id: machine.id,
          cashIn: formatCurrency(machine.cashIn),
          cashOut: `-${formatCurrency(machine.cashOut)}`,
        }))}
        columns={[
          {
            title: 'Machine',
            dataIndex: 'id',
          },
          {
            title: 'Cash In',
            dataIndex: 'cashIn',
          },
          {
            title: 'Cash Out',
            dataIndex: 'cashOut',
          },
        ]}
        pagination={false}
      />
    </div>
  );
}

export default LocationsItem;
