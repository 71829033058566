// src/components/DateRangeRadio/index.js

import { Radio } from "antd";
import './style.scss';
import { useNavigate } from 'react-router-dom'; // Atualizado para useNavigate

const DateRangeRadio = ({
                          label = 'Select the date range:',
                          options = [
                            {
                              value: 'day',
                              label: 'Day',
                            },
                            {
                              value: 'week',
                              label: 'Week',
                            },
                            {
                              value: 'month',
                              label: 'Month',
                            },
                            {
                              value: 'custom',
                              label: 'Custom',
                            },
                          ],
                          rangeType,
                          setRangeType
                        }) => {
  const navigate = useNavigate(); // Atualizado para useNavigate

  const handleChange = (e) => {
    const value = e.target.value;
    setRangeType(value);
    if (value === 'custom') {
      navigate('/dashboard/custom'); // Atualizado para navigate
    }
  };

  return (
      <div className="dateRangeRadio form-item">
        {label &&
            <div className="form-item-label">
              {label}
            </div>
        }
        <Radio.Group
            value={rangeType}
            size="large"
            onChange={handleChange}
        >
          {options && options.map((opt, key) =>
              <Radio.Button className="dateRangeRadio-item" key={key} value={opt.value}>{opt.label}</Radio.Button>
          )}
        </Radio.Group>
      </div>
  );
};

export default DateRangeRadio;