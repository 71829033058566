import { Link } from "react-router-dom";
import {
    PieChartOutlined,
    AppstoreOutlined,
    FlagOutlined,
    SettingOutlined
} from '@ant-design/icons';
import './style.scss';
import { message } from 'antd';
import { useStoreInContext } from 'store/main';

const Menu = ({ location }) => {
    const loggedUser = useStoreInContext(state => state.loggedUser);

    const showComingSoonMessage = (event) => {
        event.preventDefault();  // Impede a navegação
        message.info('Available soon.');
    };

    const menuItems = [
        {
            text: 'Dashboard',
            link: '/dashboard',
            icon: <PieChartOutlined />,
            className: 'dashboard',
            visible: loggedUser && (loggedUser.role === 'ceo' || loggedUser.role === 'manager'),
        },
        {
            text: 'Machines',
            link: '/machines',
            icon: <AppstoreOutlined />,
            className: 'machines',
            visible: loggedUser && (loggedUser.role === 'ceo' || loggedUser.role === 'manager'),
        },
        {
            text: 'Tasks',
            link: '/tasks',
            icon: <FlagOutlined />,
            className: 'tasks',
            visible: loggedUser && ['ceo', 'manager', 'technician'].includes(loggedUser.role),
        },
        {
            text: 'Manage',
            link: '/manage',
            icon: <SettingOutlined />,
            className: 'manage',
            visible: loggedUser && ['ceo', 'manager', 'technician'].includes(loggedUser.role),
        },
    ];

    return (
        <nav className="app-menu">
            <ul>
                {
                    menuItems
                        .filter(menuItem => menuItem.visible)
                        .map((menuItem, key) => (
                            <li
                                className={
                                    `${location.pathname.includes(menuItem.link) ?
                                        'menu-active ' :
                                        ''
                                    }${menuItem.className}`
                                }
                                key={key}
                            >
                                {menuItem.link ?
                                    <Link to={menuItem.link} className="menu-item-link">
                                        {menuItem.icon}
                                        <span className="menu-item-text">{menuItem.text}</span>
                                    </Link>
                                    :
                                    <a href="/" className="menu-item-link" onClick={menuItem.action}>
                                        {menuItem.icon}
                                        <span className="menu-item-text">{menuItem.text}</span>
                                    </a>
                                }
                            </li>
                        ))
                }
            </ul>
        </nav>
    );
};

export default Menu;