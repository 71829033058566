import { useOutletContext } from "react-router-dom";
import { Button, message } from 'antd';
import Header from "components/Header";
import FloatButton from "components/FloatButton";
import CardList from "components/CardList";
import LocationForm from './LocationForm';
import './style.scss';
import { EnvironmentOutlined, TagOutlined } from "@ant-design/icons";
import { createLocation, listLocations, updateLocation, deleteLocation } from '../../api/smaService';
import { useEffect, useState } from 'react';

function Locations() {
    const { setDrawerOpen, setDrawerProps } = useOutletContext();
    const [locations, setLocations] = useState(null);

    // Fetch locations on component load
    useEffect(() => {
        const fetchLocations = async () => {
            const result = await listLocations();
            if (result.success) {
                setLocations(result.data);
            } else {
                message.error(result.message);
            }
        };
        fetchLocations();
    }, []);

    const refreshLocations = async () => {
        const result = await listLocations();
        if (result.success) {
            setLocations(result.data);
        } else {
            message.error(result.message);
        }
    };

    const formattedCardListData = (cardData, actions) => {
        if (!cardData) return [];

        return cardData.map((item) => ({
            link: `/manage/locations/${item.id}`,
            title: item.name,
            list: [
                {
                    icon: <EnvironmentOutlined />,
                    text: `${item.city}/${item.state}`
                },
                {
                    icon: <TagOutlined />,
                    text: `ID: ${item.location_code}`
                },
            ],
            actions: [
                {
                    action: () => actions.handleEditItem(item),
                    name: 'Edit Location'
                },
                {
                    action: () => actions.handleDeleteItem(item),
                    name: 'Delete Location'
                },
            ]
        }));
    };

    const deleteLocationOnDatabase = async (location) => {
        try {
            let result = await deleteLocation(location.id);
            if (result.success) {
                message.success(result.message);
                await refreshLocations(); // Refresh the list after deletion
                setDrawerOpen(false);
            } else {
                message.error(result.message);
            }
        } catch (error) {
            console.error('Error:', error.message);
            message.error(`Falha na atualização: ${error.message}`);
        }
    };

    const updateLocationOnDatabase = async (location) => {
        try {
            let result = await updateLocation(location.id, location);
            if (result.success) {
                message.success(result.message);
                await refreshLocations(); // Refresh the list after update
                setDrawerOpen(false);
            } else {
                message.error(result.message);
            }
        } catch (error) {
            console.error('Error:', error.message);
            message.error(`Falha na atualização: ${error.message}`);
        }
    };

    const createLocationOnDatabase = async (newLocation) => {
        try {
            let result = await createLocation(newLocation);
            if (result.success) {
                message.success(result.message);
                await refreshLocations(); // Refresh the list after creation
                setDrawerOpen(false);
            } else {
                message.error(result.message);
            }
        } catch (error) {
            console.error('Error:', error.message);
            message.error(`Falha na atualização: ${error.message}`);
        }
    };

    const handleNewItem = () => {
        setDrawerProps({
            closable: false,
            push: true,
            title: 'New Location',
            height: 640,
            content: <LocationForm
                action={createLocationOnDatabase}
                setDrawerOpen={setDrawerOpen} />
        });
        return setDrawerOpen(true);
    };

    const handleEditItem = (item) => {
        setDrawerProps({
            closable: false,
            push: true,
            title: 'Edit Location',
            height: 640,
            content: <LocationForm
                itemData={item}
                action={updateLocationOnDatabase}
                setDrawerOpen={setDrawerOpen} />
        });
        return setDrawerOpen(true);
    };

    const handleDeleteItem = (item) => {
        setDrawerProps({
            closable: false,
            push: true,
            title: 'Delete Location',
            height: 320,
            content: <Button
                onClick={() => {
                    deleteLocationOnDatabase(item)
                    return setDrawerOpen(false)
                }}
                danger>
                Confirm deletion
            </Button>
        });
        return setDrawerOpen(true);
    };

    const dataList = formattedCardListData(locations, { handleEditItem, handleDeleteItem });

    return (
        <div className="Locations">
            <Header
                title={'Locations'}
                refreshAction={refreshLocations}
            />
            {locations ?
                <CardList
                    data={dataList}
                    createAction={handleNewItem}
                />
                :
                <>LOADING...</>
            }
            <FloatButton action={handleNewItem} />
        </div>
    );
}

export default Locations;