import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Form, Input, Button, List, message } from 'antd';
import { createInteraction, listInteractions } from 'api/smaService';
import './style.scss';

function InteractionForm({ task }) {
    const [form] = Form.useForm();
    const [interactions, setInteractions] = useState([]);

    // Carregar interações ao carregar o formulário
    useEffect(() => {
        const fetchInteractions = async () => {
            try {
                const response = await listInteractions(task.id);
                setInteractions(response.data);
            } catch (error) {
                message.error('Failed to load interactions');
            }
        };
        fetchInteractions();
    }, [task.id]);

    const onFinish = async (values) => {
        values.ticket = task.id;
        try {
            await createInteraction(values);
            form.resetFields();
            message.success('Interaction added successfully!');
            // Atualizar a lista de interações após adicionar uma nova
            const updatedInteractions = await listInteractions(task.id);
            setInteractions(updatedInteractions.data);
        } catch (error) {
            message.error('Failed to add interaction');
        }
    };

    return (
        <div className="InteractionForm">
            <List
                header={<strong>Interactions for {task.description}</strong>}
                bordered
                dataSource={interactions}
                renderItem={interaction => (
                    <List.Item>
                        <List.Item.Meta
                            title={moment(interaction.created_at).format('MM/DD/YYYY h:mm A')}
                            description={<div style={{ whiteSpace: 'pre-line' }}>{interaction.description}</div>}
                        />
                    </List.Item>
                )}
            />
            <div style={{ marginTop: '20px' }}>
                <Form form={form} onFinish={onFinish}>
                    <Form.Item
                        name="description"
                        rules={[{ required: true, message: 'Please enter your interaction' }]}
                    >
                        <Input.TextArea rows={4} placeholder="Add your interaction..." />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Add Interaction
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default InteractionForm;