import { Empty, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CardListItem from './CardListItem';
import './style.scss';

const CardList = ({ data, createAction, className = '' }) => {
  return (
    <div className={`cardList ${className}`}>
      {
        data ?
          data.map((item, key) => <CardListItem key={key} data={item} />)
          :
          <div className="empty-list">
            <Empty
              description="No data found."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            >
              <Button onClick={createAction}>Add new <PlusOutlined /></Button>
            </Empty>
          </div>
      }
    </div>
  );
};

export default CardList;