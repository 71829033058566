import { DashboardOutlined, DollarOutlined, FallOutlined, LineChartOutlined, RiseOutlined } from '@ant-design/icons';
import './style.scss';
import { formatCurrency } from 'utils/formatters';

const CashCards = ({
  rangeType,
  growth,
  profit,
  inCoins,
  cashIn,
  cashOut,
}) => {

  return (
    <div className="cashCards">
      {growth !== null && growth !== undefined &&
        <div className={`cashCard growthBar${growth > 0 ? ' positive' : ' negative'}`}>
          <DashboardOutlined className="bg-icon" />
          <DashboardOutlined />
          <span className="growthPercentage">{growth > 0 ? '+' : ''}{growth}%</span>
          <span className="growthDescription">Growth compared<br />to last {rangeType}</span>
        </div>
      }
      {cashIn &&
        <div className="cashCard green">
          <RiseOutlined />
          <div className="cashCard-data">
            <div className="cashCard-number">{cashIn}</div>
            <div className="cashCard-label">Cash In</div>
          </div>
        </div>
      }
      {cashOut &&
        <div className="cashCard red">
          <FallOutlined />
          <div className="cashCard-data">
            <div className="cashCard-number">{cashOut}</div>
            <div className="cashCard-label">Cash Out</div>
          </div>
        </div>
      }
      {profit &&
        <div className="cashCard purple">
          <LineChartOutlined />
          <div className="cashCard-data">
            <div className="cashCard-number">{profit}</div>
            <div className="cashCard-label">Profit</div>
          </div>
        </div>
      }
      {/* {inCoins &&
        <div className="cashCard yellow">
          <DollarOutlined />
          <div className="cashCard-data">
            <div className="cashCard-number">{inCoins}</div>
            <div className="cashCard-label">Total in Coins</div>
          </div>
        </div>
      } */}
    </div>
  );
};

export default CashCards;
