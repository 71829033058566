import {Button, Form, Input, message} from 'antd';
import {ArrowRightOutlined} from '@ant-design/icons';
import './style.scss';
import {updateUserProfile} from "../../../api/smaService";

function AccountForm({itemData, action, setDrawerOpen}) {

    const onFinish = async (values) => {
        const {email, name, password, confirm_password} = values;

        const updateData = {
            email,
            name,
        };

        // Adiciona a senha apenas se os campos estiverem preenchidos
        if (password && confirm_password) {
            updateData.password = password;
            updateData.confirm_password = confirm_password;
        }

        try {
            let result = await updateUserProfile(updateData);
            if (result.success) {
                message.success('Perfil atualizado com sucesso!');
                // atualizar os dados do usuário no estado
                action({
                    ...itemData,
                    email,
                    name,
                });
                setDrawerOpen(false);
            } else {
                message.error(result.message);
            }

        } catch (error) {
            console.error('Error:', error.message);
            message.error(`Falha na atualização: ${error.message}`);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="Accounts">
            <div className="manage-accounts-form">
                <Form
                    layout="vertical"
                    name="manage-accounts-form"
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    style={{maxWidth: 600}}
                    initialValues={{
                        name: itemData?.name || undefined,
                        email: itemData?.email || undefined,
                        password: undefined,
                        confirm_password: undefined,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Account name"
                        name="name"
                        rules={[{required: true, message: 'Please input the account name!'}]}
                    >
                        <Input placeholder="Insert the name"/>
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{required: true, message: 'Please input the Account Email!'}]}
                    >
                        <Input placeholder="Insert the account ID"/>
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{required: false}]}
                    >
                        <Input.Password type="password" placeholder="Insert the password"/>
                    </Form.Item>

                    <Form.Item
                        label="Confirm password"
                        name="confirm_password"
                        dependencies={['password']}
                        rules={[
                            {required: false},
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password type="password" placeholder="Confirm the password"/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {itemData ? 'Update Account' : 'Create account'}
                            <ArrowRightOutlined/>
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default AccountForm;