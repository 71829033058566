const { v4: uuidv4 } = require('uuid');

export const getLocationNameById = (locations, locationId) => {
  const locationName = locations?.find((location) => location.id === locationId)?.name;

  return locationName || '';
}

export const getLocationDataById = (locations, id) => locations?.find((location) => location.id === id)
export const getMachineDataById = (machines, id) => machines?.find((machine) => machine.id === id)
export const getTaskDataById = (tasks, id) => tasks?.find((task) => task.id === id)
export const getUserDataById = (users, id) => users?.find((user) => String(user.id) === String(id))
export const getTechniciansData = (users) => users ? users.filter((user) => String(user.roleId) === '3') : [];
export const getManagersData = (users) => users ? users.filter((user) => String(user.roleId) === '2') : [];
export const getCompanyDataById = (companies, id) => companies?.find((company) => company.id === id)

export const generateShortUUID = () => {
  const fullUuid = uuidv4();
  return fullUuid.replace(/-/g, '').substring(0, 8);  // Remove hyphens and take the first 8 characters
};