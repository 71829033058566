import { Table } from 'antd';
import './style.scss';
    
const TopItemTable = ({
  title,
  data,
  columns,
  className = '',
  pagination
}) => {
  return (
    <div className={`topItemTable ${className}`}>
      {title && <div className="section-title">{title}</div>}
      <Table
        columns={columns}
        dataSource={data}
        pagination={pagination}
        size="small"
      />
    </div>
  );
};

export default TopItemTable;