import {login, getUserInfo, listLocations, listMachines} from '../api/smaService';
import { message } from 'antd';

export async function handleLogin(email, password, setLoggedUser, setLocations, setMachines, navigate) {
    let access_token, refresh_token;
    try {
        let loginSuccess = false;
        if (email && password) {
            // Login com credenciais
            const loginData = await login(email, password);
            if(loginData.success){
                access_token = loginData.data.access;
                refresh_token = loginData.data.refresh;
                loginSuccess = true;
                localStorage.setItem('access_token', access_token);
                localStorage.setItem('refresh_token', refresh_token);
            }
        }

        if(loginSuccess){
            const requestProfileInfo = await getUserInfo(access_token);
            if(requestProfileInfo.success){
                const userData = {
                    access_token,
                    refresh_token,
                    role: requestProfileInfo.data.role,
                    name: requestProfileInfo.data.name,
                    email: requestProfileInfo.data.email,
                    company: requestProfileInfo.data.company
                };

                listLocations().then(result => {
                    if (result.success) {
                        setLocations(result.data);
                    } else {
                        message.error(result.message);
                    }
                });

                listMachines().then(result => {
                    console.log("Teste Machines: ", result.data);
                    setMachines(result.data);
                });

                setLoggedUser(userData);
                navigate && navigate("/dashboard");
            }

        }else{
            message.error('Login failed!');
        }


    } catch (error) {
        console.error('Login error:', error.message);
        message.error('Login failed! ' + error.message);
    }
}

export function handleLogout(navigate, setLoggedUser) {
    try {
        // Remove tokens do localStorage
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');

        setLoggedUser(null);

        // Redireciona para a página de login
        navigate('/login');
    } catch (error) {
        console.error('Logout error:', error.message);
        message.error('Logout failed! ' + error.message);
    }
}
