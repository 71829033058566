import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, Card } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { listTicketStatus, listLocations, listMachinesByLocation, listTechnicians } from 'api/smaService';
import { useStoreInContext } from 'store/main';
import './style.scss';

const { TextArea } = Input;

function TaskForm({ itemData, action, setDrawerOpen }) {
    const { loggedUser } = useStoreInContext(state => state);
    const [statusOptions, setStatusOptions] = useState([]);
    const [locationsOptions, setLocationsOptions] = useState([]);
    const [machinesOptions, setMachinesOptions] = useState([]);
    const [techniciansOptions, setTechniciansOptions] = useState([]);
    const [selectedLocationId, setSelectedLocationId] = useState(itemData?.machine?.location?.id || null);

    const loadMachinesOptions = async (locationId) => {
        if (locationId && (loggedUser.role === 'ceo' || loggedUser.role === 'manager')) {
            const result = await listMachinesByLocation(locationId);
            if (result.success) {
                setMachinesOptions(result.data.map(machine => ({
                    value: machine.id,
                    label: machine.name,
                })));
            }
        }
    };

    useEffect(() => {

        const loadStatusOptions = async () => {
            const result = await listTicketStatus();
            console.log("Listagem de status: ", result);
            if (result.success) {
                setStatusOptions(result.data.map(status => ({
                    value: status.id,
                    label: status.status,
                })));
            }
        };

        const loadLocationsOptions = async () => {
            const result = await listLocations();
            if (result.success) {
                setLocationsOptions(result.data.map(location => ({
                    value: location.id,
                    label: location.name,
                })));
            }
        };

        const loadTechniciansOptions = async () => {
            if (loggedUser.role === 'ceo' || loggedUser.role === 'manager') {
                const result = await listTechnicians();
                if (result.success) {
                    setTechniciansOptions(result.data.map(technician => ({
                        value: technician.id,
                        label: technician.name,
                    })));
                }
            }
        };

        loadStatusOptions();
        loadLocationsOptions();

        if (loggedUser.role === 'ceo' || loggedUser.role === 'manager') {
            loadTechniciansOptions();
        }

        if (selectedLocationId) {
            loadMachinesOptions(selectedLocationId);
        }
    }, [selectedLocationId, loggedUser.role]);

    const onLocationChange = (locationId) => {
        setSelectedLocationId(locationId);
        loadMachinesOptions(locationId);
    };

    const onFinish = (values) => {
        if (loggedUser.role === 'technician' && (values.ticket_status === 1 || values.ticket_status === 'OPEN')) {
            console.error("Technicians cannot set status to OPEN.");
            return;
        }

        if(itemData && itemData.id){
            action(itemData.id, {
                ...values,
                ticket_status: itemData ? values.ticket_status : statusOptions.find(status => status.label === 'OPEN').value,
            });
        }else{
            console.log("Valores: ", values);
            action({
                ...values,
                ticket_status: itemData ? values.ticket_status : statusOptions.find(status => status.label === 'OPEN').value,
            });
        }


        setDrawerOpen(false);
    };

    const technicianStatusOptions = statusOptions.map(option => ({
        ...option,
        disabled: option.label === 'OPEN',
    })).filter(option => ['DOING', 'DONE'].includes(option.label));

    const isTechnician = loggedUser.role === 'technician';

    return (
        <div className="NewTasks">
            <div className="new-tasks-form">
                <Form
                    layout="vertical"
                    name="new-tasks-form"
                    initialValues={{
                        id: itemData?.id || undefined,
                        title: itemData?.title || '',
                        description: itemData?.description || '',
                        location: itemData?.machine?.location?.id || undefined,
                        machine: itemData?.machine?.id || undefined,
                        technician: itemData?.technician?.id || undefined,
                        ticket_status: itemData?.ticket_status || 'OPEN',
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    {isTechnician ? (
                        <>
                            <Form.Item
                                label="Task Title"
                                name="title"
                            >
                                <Card className="detail-card">
                                    <p>{itemData?.title}</p>
                                </Card>
                            </Form.Item>

                            <Form.Item
                                label="Description"
                                name="description"
                            >
                                <Card className="detail-card">
                                    <p>{itemData?.description}</p>
                                </Card>
                            </Form.Item>

                            <Form.Item
                                label="Location"
                                name="location"
                            >
                                <Card className="detail-card">
                                    <p>{itemData?.machine?.location?.name}</p>
                                </Card>
                            </Form.Item>

                            <Form.Item
                                label="Machine"
                                name="machine"
                            >
                                <Card className="detail-card">
                                    <p>{itemData?.machine?.name}</p>
                                </Card>
                            </Form.Item>

                            <Form.Item
                                label="Technician"
                                name="technician"
                            >
                                <Card className="detail-card">
                                    <p>{itemData?.technician?.name}</p>
                                </Card>
                            </Form.Item>

                            <Form.Item label="Status" name="ticket_status" rules={[{ required: true, message: 'Please select a status!' }]}>
                                <Select options={technicianStatusOptions} />
                            </Form.Item>
                        </>
                    ) : (
                        <>
                            <Form.Item label="Task Title" name="title">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Description" name="description">
                                <TextArea rows={4} />
                            </Form.Item>
                            <Form.Item label="Location" name="location">
                                <Select options={locationsOptions} onChange={onLocationChange} />
                            </Form.Item>
                            <Form.Item label="Machine" name="machine">
                                <Select options={machinesOptions} />
                            </Form.Item>
                            <Form.Item label="Technician" name="technician">
                                <Select options={techniciansOptions} />
                            </Form.Item>
                            <Form.Item label="Status" name="ticket_status" rules={[{ required: true, message: 'Please select a status!' }]}>
                                <Select options={statusOptions} />
                            </Form.Item>
                        </>
                    )}
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {itemData ? 'Update Task' : 'Create Task'}
                            <ArrowRightOutlined />
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default TaskForm;